<template>
  <van-nav-bar
    title="yqm"
    left-text=""
    left-arrow
    @click-left="onGoBack"
  />
  <div class="qrcode_box">
    <img
         class="qrcode_canvas"
         id="qrcode_canvas"
         ref="qrcode_canvas"
         alt="二维码本图"
    />
    <canvas
      width="300"
      height="400"
      class="canvas"
      ref="canvas"
    >
    </canvas>
    <span style='display:block;margin:0px auto'>长按保存图片</span>
  </div>
</template>
<script>
import VueQr from 'vue-qr/src/packages/vue-qr.vue'
import QRCode from "qrcode";
import { useUserStore } from '@/store/user.ts'

const userStore = useUserStore()

export default {
  components: {
    VueQr,
    useUserStore
  },

  data(){
    return{
    text:'',
    qrSize:250,
    qrUrl:'',
    qrTextSize:14,
    qrText:"扫描二维码注册",
    qrcodeImgSrc:'',
    }
  },
  mounted(){
    this.onmakerc()
    this.handleQrcodeToDataUrl();
  },
  updated() {
     this.handleQrcodeToDataUrl();
   },
	methods: {
  //操作函数
  
    onmakerc() {
      let base_host_url='http://'+window.location.host
      this.qrUrl = base_host_url+'/#/register?invite_user='+userStore.userInfo.name+'&invite_phone='+userStore.userInfo.phone;
      console.log(this.qrUrl)
    },
    
    handleQrcodeToDataUrl() {
       let qrcode_canvas = this.$refs.qrcode_canvas;
       let canvas = this.$refs.canvas;
       const that = this;
       QRCode.toDataURL(
         this.qrUrl,
         { errorCorrectionLevel: "H" ,
         color: {
          dark: "#003366", //前景色
          light: "#FFFFCC" //背景色
         },
         },
         (err, url) => {
           qrcode_canvas.src = url;
           // 画二维码里的logo// 在canvas里进行拼接
           let ctx = canvas.getContext("2d");
            ctx.fillStyle='#fff';
            ctx.fillRect(0,0,300,400);
            setTimeout(() => {
              ctx.drawImage(qrcode_canvas, 28, 80, that.qrSize, that.qrSize);
              ctx.font = "bold " + that.qrTextSize + "px Arial";
              ctx.textBaseline = "top"; //设置绘制文本时的文本基线。
              ctx.fillStyle = "#333";
              ctx.fillText(that.qrText, 100, 350);
              ctx.fillText('邀请人('+userStore.userInfo.name+')', 100, 45);
              qrcode_canvas.src = canvas.toDataURL();
           }, 0);
         }
       );
     }
  }
};
</script>

<style lang="less">
.main{
   width:60%;
   margin:10% auto;
   padding: 20% auto;
   background:#f9f9fb ;
   text-align: center;
   border-radius:5px;
   display:block;
  .top{
    display:block;
    height: 50px;
    line-height: 50px;
    margin:20px 0px 20px 0px;
  }
}
.qrcode_box{
  width:100%;
  img{
    display: block;
    margin: 50px auto;
    border:1px solid #cfcbcb;
    border-radius:5px;
  }
  span{
    text-align: center;
    //display: inline-block;
    //width: 100px;
    //margin: 50px auto;
    //border:1px solid #cfcbcb;
    //border-radius:5px;
  }
}
.qrcode_box  canvas{
   display: none;
}
</style>
